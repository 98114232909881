<div
  class="multiselect-autocomplete-container mt-1"
  [attr.aria-labelledby]="_formField?.getLabelId()"
  (focusin)="onFocusIn($event)"
  (focusout)="onFocusOut($event)"
>
  <mat-chip-grid #chipList>
    @if (showChipLoader) {
      <app-skeleton-loader
        [theme]="{ display: 'block', height: '1rem', width: '40%', borderRadius: '16px' }"
        class="chip-loader"
      >
      </app-skeleton-loader>
    } @else {
      @if (!customChipTemplate) {
        @for (item of selectedItems; track item) {
          <mat-chip-row (removed)="remove(item)">
            <div appEllipsisTooltip>
              {{
                displayProperty
                  ? returnKey
                    ? itemsMap[item]
                      ? itemsMap[item][displayProperty]
                      : null
                    : item[displayProperty]
                  : item
              }}
            </div>
            @if (isChipRemovable) {
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            }
          </mat-chip-row>
        }
      } @else {
        @for (item of selectedItems; track item) {
          <mat-chip-row (removed)="remove(item)">
            <span [innerHTML]="customChipTemplate(item, itemsMap)"></span>
            @if (isChipRemovable) {
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            }
          </mat-chip-row>
        }
      }
    }
    @if (!selectedItems.length && showDefaultPlaceholder) {
      <mat-chip-row class="placeholder">{{ defaultPlaceHolderTxt }}</mat-chip-row>
    }
    <!-- <mat-placeholder class="placeholder" *ngIf="!selectedItems.length && showDefaultPlaceholder"></mat-placeholder> -->
    <input
      type="text"
      [style.height]="showChipLoader ? '0px' : 'inherit'"
      #inputTrigger
      [required]="required ? '' : null"
      (focusin)="onFocusIn($event)"
      (focusout)="onFocusOut($event)"
      [matAutocomplete]="autoComplete"
      [formControl]="itemControl"
      placeholder="{{ inputPlaceHolderTxt }}"
      [matChipInputFor]="chipList"
    />
  </mat-chip-grid>
  <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="onSelectedItems($event)">
    @if (items.length) {
      @if (!customOptionTemplate) {
        @for (item of filteredItems; track item) {
          <mat-option [value]="returnKey ? item[returnKey] : item">
            {{ dropdownTemplate ? dropdownTemplate : displayProperty ? item[displayProperty] : item }}
          </mat-option>
        }
      } @else {
        @for (item of filteredItems; track item) {
          <mat-option [value]="returnKey ? item[returnKey] : item">
            <span [innerHTML]="customOptionTemplate(item)"></span>
          </mat-option>
        }
      }
    } @else {
      @for (i of [1, 2, 3, 4, 5]; track i) {
        <mat-option disabled>
          <app-skeleton-loader [theme]="{ height: '1rem', marginTop: '10px', display: 'block', width: '100vw' }">
          </app-skeleton-loader>
        </mat-option>
      }
    }
  </mat-autocomplete>
</div>
