import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { TranslateService } from '@ngx-translate/core';
import { Subject, map, startWith } from 'rxjs';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TextOverflowTooltipDirective } from '../../directives/overflow-directive/overflow-tooltip.directive';
import { SkeletonLoaderComponent } from '../../modules/skeleton-loader/skeleton-loader.component';
import { MatChipsModule } from '@angular/material/chips';

@Component({
    selector: 'app-multiselect-autocomplete-component',
    templateUrl: 'multiselect-autocomplete.component.html',
    styleUrls: ['multiselect-autocomplete.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: MultiselectAutocompleteComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatChipsModule, SkeletonLoaderComponent, TextOverflowTooltipDirective, MatIconModule, MatAutocompleteModule, FormsModule, ReactiveFormsModule, MatOptionModule]
})
export class MultiselectAutocompleteComponent
  implements AfterViewInit, ControlValueAccessor, MatFormFieldControl<MultiselectAutocompleteComponent>, OnDestroy {
  static nextId = 0;

  @ViewChild(MatAutocompleteTrigger)
  private inputTrigger: MatAutocompleteTrigger;

  itemControl: FormControl;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType?: string = 'multiselect-autocomplete';
  id = `multiselect-autocomplete-${MultiselectAutocompleteComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    return !this.selectedItems.length;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
    this._cdr.markForCheck();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
    this._cdr.markForCheck();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.itemControl.disable() : this.itemControl.enable();
    this.stateChanges.next();
    this._cdr.markForCheck();
  }
  private _disabled = false;

  @Input()
  get value() {
    return this.selectedItems;
  }
  set value(value: any) {
    if (value) {
      this.selectedItems = value;
      this.stateChanges.next();
      this._cdr.markForCheck();
    }
  }

  get errorState(): boolean {
    return this._required && !this.selectedItems.length && (!!this.touched || !!this.ngControl.control.touched);
  }
  @Input() showChipLoader: boolean = false;
  @Input() isChipRemovable: boolean = true;
  @Input('customOptionTemplate') customOptionTemplate: (row: any) => string;
  @Input('customChipTemplate') customChipTemplate: (row: any, itemsMap: any) => string;
  @Input() defaultPlaceHolderTxt: string = this.translateService.instant('publisher.report.allSelected');
  @Input() inputPlaceHolderTxt: string;
  @Input() dropdownTemplate: TemplateRef<any>;

  public returnKey: string;
  selectedItems: any[] = new Array<any>();
  filteredItems: any[];

  @Input() set returnValue(val: string) {
    if (val) {
      this.returnKey = val;
      this.convertToMap(val);
      this._cdr.markForCheck();
    }
  }

  items: any[] = [];
  itemsMap: any;
  @Input() set dropdownArray(value: any[]) {
    if (value && value.length) {
      this.items = value;
      this._cdr.markForCheck();
      this.itemControl.valueChanges
        .pipe(
          startWith(''),
          map(val => (!!val ? val : '')),
          map(item =>
            typeof item === 'string'
              ? this._filter(item)
              : this._filter(this.displayProperty ? item[this.displayProperty] : item)
          )
        )
        .subscribe(data => {
          this.filteredItems = data;
          this.convertToMap(this.returnKey);
          this._cdr.markForCheck();
        });
    } else {
      //TO-DO: set no value template
    }
  }

  @Input() displayProperty: string;
  @Input() showDefaultPlaceholder: boolean = false;
  @Input() inputPlaceHolder: string;
  @Input() matLabel: string;
  @Input('aria-describedby') userAriaDescribedBy: string;
  constructor(
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    private translateService: TranslateService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private _cdr: ChangeDetectorRef
  ) {
    this.itemControl = new FormControl(null);

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this._cdr.markForCheck();
  }

  onFocusIn(event: FocusEvent) {
    !this.focused && (this.focused = true);
    this.stateChanges.next();
    this._cdr.detectChanges();
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
      this._cdr.markForCheck();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.multiselect-autocomplete-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }
  onContainerClick(event: MouseEvent): void {
    this.inputTrigger._onChange('');
    this.inputTrigger.openPanel();
  }
  writeValue(value: any) {
    !!value && (this.selectedItems = value);
    this._cdr.markForCheck();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
    this._cdr.detectChanges();
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
    this._cdr.detectChanges();
  }

  convertToMap(val) {
    this.itemsMap = this.items.reduce(function(map, obj) {
      map[obj[val]] = obj;
      return map;
    }, {});
    this._cdr.markForCheck();
  }

  private _filter(value: string): any[] {
    return !!value
      ? this.items.filter(item =>
          (this.displayProperty ? item[this.displayProperty] : item).toLowerCase().includes(value.toLowerCase())
        )
      : this.items;
  }

  onSelectedItems(event: MatAutocompleteSelectedEvent): void {
    this.selectedItems = [...this.selectedItems];
    if (!this.selectedItems.includes(event.option.value)) {
      this.selectedItems.push(event.option.value);
    }
    this._cdr.markForCheck();
    this.propogateChanges();
  }

  remove(item): void {
    this.selectedItems = [...this.selectedItems];
    const index = this.selectedItems.indexOf(item);
    index >= 0 && this.selectedItems.splice(index, 1);
    this._cdr.markForCheck();
    this.propogateChanges();
  }

  propogateChanges() {
    this.selectedItems = [...this.selectedItems];
    this.ngControl.control.setValue(this.selectedItems);
    this.ngControl.control.updateValueAndValidity();
    this.itemControl.setValue(null);
    this._cdr.detectChanges();
    this.stateChanges.next();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._cdr.markForCheck();
  }

  ngAfterViewInit() {
    this._cdr.detectChanges();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }
}
